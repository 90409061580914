<template>
    <CContainer>
      <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <h3>Buscar Email PDF</h3>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol col="4">
                  <label type="text" class="col-sm-9 "><span>
                  <strong>Sub Cuentas</strong>
                  </span></label>
                    <CSelect
                          label=""
                          :options="itemsSubCuentas"
                          was-validated
                          :value.sync="usuario"
                          :disabled="desahabilitarModoEnvioSubCuenta"
                          >
                      </CSelect>
              </CCol>
              <CCol col="4">
                <CInput
                    label="Ingresa email a buscar"
                    placeholder="Ingresa email a buscar"
                    autocomplete="off"
                    v-model="email_filter"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol col="4">
                <br>
                <button type="button"  class="btn btn-primary btn-lg btn-block" v-if="$can('reportes.index')" @click="buscarEmail()">Buscar</button> 
              </CCol>
            </CRow>
        </CCardBody>
        </CCard>
       
        <CCard>
          <CCardHeader>
             <CRow class="justify-content-between">
              <CCol col="8"><h3>Cantidad registros encontrados: {{ items.length }}</h3></CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              hover
              striped
              :items="items"
              :fields="fields"
              :items-per-page="30"
              :pagination="{ doubleArrows: false, align: 'center'}"
              column-filter
              @page-change="pageChange"
            >
                <template #accion="{item, index}">
                  <td class="py-2">
                    <CButton
                      color="warning"
                      variant="outline"
                      square
                      size="sm"
                      disabled
                      v-if="item.attachment_file != '' && item.estado != 'Mensaje enviado'"
                    >
                      Descargar Archivo
                    </CButton>
                  </td>
                  <td class="py-2">
                    <CButton
                      color="warning"
                      variant="outline"
                      square
                      size="sm"
                      @click="descargarArchivoEmail(item.attachment_file,item.attachment_name)"
                      v-if="item.attachment_file != '' && item.estado == 'Mensaje enviado'"
                    >
                      Descargar Archivo
                    </CButton>
                  </td>
                  <td class="py-2">
                    <CButton
                      color="success"
                      variant="outline"
                      square
                      size="sm"
                      @click="descargarInformacionPDF(item.id_mail)"
                      v-if="item.estado == 'Mensaje enviado'"
                    >
                      Generar PDF
                    </CButton>
                  </td>
                </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <div>
            <CToaster :autohide="5000" class="d-inline-block">
               <template v-for="toast in mostrarNotificacion">
                  <CToast
                    :key="'toast'+toast"
                    :show="true"
                    color="success"
                    header="Notificacion Exitosa"
                  >
                    {{mensaje_success}}
                  </CToast>
                </template>   
                <template v-for="toast1 in mostrarNotificacionAlerta">
                  <CToast
                    :key="'toast1'+toast1"
                    :show="true"
                    color="warning"
                    header="Notificacion Alerta"
                  >
                    {{mensaje_warning}}
                  </CToast>
                </template>
                <template v-for="toast2 in mostrarNotificacionError">
                  <CToast
                    :key="'toast2'+toast2"
                    :show="true"
                    color="danger"
                    header="Notificacion Alerta"
                  >
                    Error Comunicarse Con Soporte
                  </CToast>
                </template>  
            </CToaster>
          </div>
    </CContainer>
    
  </template>
  
  <script>
  import { CChartBar, CChartPie } from "@coreui/vue-chartjs";
  import domtoimage from "dom-to-image";
  import Multiselect from "vue-multiselect";
  import Axios from 'axios'
  export default {
    name: "BusquedaMail",
    components: { CChartBar, CChartPie, domtoimage, Multiselect },
    data() {
      return {
        mostrarNotificacion: 0,
        mensaje_success: "Datos Guardado Correctamente.",
        mostrarNotificacionAlerta: 0,
        mostrarNotificacionError: 0,
        mensaje_warning: "Debe Completar Los Datos Vacio",
        historial_select: "mail",
        email_filter:"",
        items: [],
        itemsSubCuentas: [],
        fields: [
          { key: "id_mail", label: "Id Mail" },
          {
            key: "asunto",
            label: "Asunto",
            _classes: "font-weight-bold",
          },
          { key: "proyecto", label: "Proyecto", _classes: "font-weight-bold" },
          { key: "estado", label: "Estado", _classes: "font-weight-bold" },
          { key: "fecha_procesamiento", label: "Fecha Procesamiento", _classes: "font-weight-bold" },
          { key: "evento_envio", label: "Evento Envio", _classes: "font-weight-bold" },
          { key: "lectura_fecha", label: "Fecha Lectura", _classes: "font-weight-bold" },
          { key: 'email_copia',label:'Con copia'},
          { key: "tipo_envio", label: "Tipo Envio", _classes: "font-weight-bold" },
          {
            key: "accion",
            label: "",
            _style: { width: "1%" },
            sorter: false,
            filter: false,
          },
        ],
        activePage: 1,
        usuario: 0,
        desahabilitarModoEnvioSubCuenta:true,
      };
    },
    methods: {
      goBack() {
        this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({ path: "/roles" });
      },
      descargarReporte(nombre) {
        var url = this.$httpUrlMail + `/mail/reporte/descargar/${nombre}`;
        window.open(url, "_blank");
      },
      buscarEmail() {
          this.$store.dispatch("auth/loading_http", "visible");
          this.$http
            .post(this.$httpUrlMail+"/mail/busqueda/mail",{url:"/mail/busqueda/mail",iduser:this.usuario,email:this.email_filter},{ withCredentials: false })
            .then((response) => {
              let data = response.data;
              if (data.length == 0) {
                  this.items = 0;
                  this.mostrarNotificacionAlerta++;
                  this.mensaje_warning = "No hay registros disponibles";             
              }else{
                this.items = data;
              }
            })
            .catch((e) => {
              this.mostrarNotificacionError++;
            })
            .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      },
      pageChange(val) {
        this.$router.push({ query: { page: val } });
      },
      printDate: function (time) {
        return new Date(time).toLocaleDateString();
      },
      creditosConversorMoneda(value) {
        const formatterDolar = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        return formatterDolar.format(value).replace("$", "");
      },
      obtenerSubcuentas() {
       
        this.$store.dispatch("auth/loading_http", "visible");
          this.$http
            .post("/mail/reporte/lista/subcuentas")
            .then((response) => {
              let data = response.data;
              if (data.length > 0) {
                this.desahabilitarModoEnvioSubCuenta = false;
                this.itemsSubCuentas = [];
                this.itemsSubCuentas.push({
                  value: 0,
                  label: "Seleccionar Usuario",
                });
                for (const iterator of data) {
                  this.itemsSubCuentas.push({
                    value: iterator.id,
                    label: iterator.name + ` (${iterator.padre})`,
                  });
                }
              } else {
                this.desahabilitarModoEnvioSubCuenta = true;
                this.itemsSubCuentas = [];
                this.usuario = 0;
              }
            })
            .catch((e) => {
              this.mostrarNotificacionError++;
            })
            .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      },
      random() {
        return Math.random().toString(36).substr(2); // Eliminar `0.`
      },
      token() {
        return this.random() + this.random(); // Para hacer el token más largo
      },
      descargarArchivoEmail(url,fileName){
        let name_arr = url;
        name_arr = name_arr.split(".");
        let name_file = "lectura_archivo_"+this.token()+"."+name_arr[name_arr.length-1]
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = name_file;
        downloadLink.setAttribute('target', '_blank');
        downloadLink.click();
      },
      descargarInformacionPDF(id_msg){
        this.$store.dispatch("auth/loading_http", "visible");
        let envio = this.items.find(item => item.id_mail == id_msg);
        let logo = this.$store.state.auth.logo_web;
          this.$http
            .post("/mail/busqueda/mail/descargar",{url:"/mail/busqueda/mail/descargar",mail:id_msg,iduser:this.usuario,email:this.email_filter,logo:logo, tipo: envio.tipo_envio})
            .then((response) => {
              let data = response.data;
              this.downloadBase64(data,"lectura_correo_"+this.token()+".pdf")
            })
            .catch((e) => {
              this.mostrarNotificacionError++;
            })
            .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      },
      downloadBase64(pdf,fileName) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

      }
    },
    mounted() {
      setTimeout(() => {
        this.obtenerSubcuentas();
      }, 100);
    },
    computed: {}
  };
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>